import React from "react";
import "./Presentation.css";
import PresentationImage from "../../assets/img/Pix.png";
import {ReactComponent as ArrowRight} from "../../assets/img/svg/arrow-right.svg";
import {Link} from "react-router-dom";

const Presentation = () => {
    return (
        <>
            <div className="presentation-container">
                <div className="presentation-image"></div>
                <div className="presentation-content">
                    <img src={PresentationImage} alt={"Presentation"}/>
                    <div className="presentation-text-container">
                        <h4>Guillaume Deneufbourg</h4>
                        <div className="line-container">
                            <div className="line"/>
                            <h5>polyglotte passionné, traducteur épris de son métier</h5>
                        </div>
                        <div className="presentation-content-text">
                            <p className="regular">
                                Je suis traducteur d’édition en profession libérale. Accrédité par la Fondation
                                néerlandaise des lettres, j’ai traduit à ce jour une vingtaine d’ouvrages littéraires du
                                néerlandais au français. Je traduis également dans le domaine de la presse et des
                                institutions internationales.
                            </p>
                            <Link to={"/bio"} className="text-decoration-none">
                                <button className="secondary">
                                    En savoir plus
                                    <ArrowRight className="arrow-right"/>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Presentation;
