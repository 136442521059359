import React, {useEffect, useState} from "react";
import "./Portfolio.css";
import Books from "../../assets/img/bibliography_books.png";
import Presentation from "../../components/Presentation/Presentation";
import {ReactComponent as ArrowDown} from "../../assets/img/svg/arrow-down.svg";
import axios, {AxiosResponse} from "axios";
import Config from "../../utils/Config";
import aggregateProperties from "../../aggregator/PropertiesAggregator";
import {styled, Tooltip, tooltipClasses, TooltipProps} from "@mui/material";


const Portfolio = () => {
    const [clickedBooks, setClickedBooks] = useState<string[]>([]);
    const [books, setBooks] = useState<any>([]);

    useEffect(() => {
        axios.get(`${Config.apis.s3}/Lists/Portfolios/listBlogs`, {
            // query URL without using browser cache
            headers: {
                "Cache-Control": "no-cache",
                Pragma: "no-cache",
                Expires: "0",
            }
        }).then((response: AxiosResponse) => {
            setBooks(() => {
                return response.data;
            })
        })
    }, [])
    const handleOnClickBook = (event: any) => {
        const bookId: string = event.currentTarget.dataset.id;

        console.log(bookId)
        if (clickedBooks.includes(bookId)) {
            const updatedClickedBooks = clickedBooks.filter((id) => id !== bookId);
            setClickedBooks(() => updatedClickedBooks);

            return;
        }

        const updatedClickedBooks = [...clickedBooks];
        updatedClickedBooks.push(bookId);
        setClickedBooks(() => updatedClickedBooks);
        console.log(clickedBooks)
    };


    const CustomWidthTooltip = styled(({className, ...props}: TooltipProps) => (
        <Tooltip {...props} classes={{popper: className}}/>
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 500,
        },
    });


    return (
        <>
            <div className="container">
                <div className="hero">
                    <div className="hero-content-container">
                        <div className="hero-content">
                            <div>
                                <div className="line-container">
                                    <div className="line"/>
                                    <h5>Portfolio</h5>
                                </div>
                                <h1>Ma bibliographie</h1>
                            </div>
                            <div className="h4-container">
                                <p className="regular">
                                    Vous trouverez sur cette page une sélection des traductions que j'ai réalisées au
                                    cours de ma carrière, avec à chaque fois la couverture de l'édition française et de
                                    l’original. En cliquant sur la couverture, vous aurez accès au résumé et à quelques
                                    informations pratiques. Pour retrouver la liste complète de mes publications,
                                    consultez <a href={"https://atlf.org/repertoire-des-traducteurs/deneufbourg/"}>mon profil</a> sur le site de l’Association française des traducteurs
                                    littéraires.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="hero-image">
                        <img src={Books} alt="Livres" height={630}/>
                    </div>
                </div>

                <div className="portfolio-books-container">
                    {books.map((book: any) => {
                        let bookProperties = aggregateProperties(book.properties);
                        return (
                            <div key={book._id} className="portfolio-book-wrapper">
                                <div
                                    className={`portfolio-book-container ${
                                        clickedBooks.includes(book._id) ? "disabled-effects" : ""
                                    }`}
                                    onClick={handleOnClickBook}
                                    data-id={book._id}
                                >
                                    <div className="portfolio-book">
                                        <img src={bookProperties.image[0].value} width={250} height={370}/>
                                        {bookProperties.imageHover[0].value && (
                                            <img src={bookProperties.imageHover[0].value} width={250} height={370}/>
                                        )}
                                    </div>
                                </div>
                                <button
                                    className="tertiary"
                                    data-id={book._id}
                                    onClick={handleOnClickBook}
                                >
                                    Read more
                                    <ArrowDown
                                        className={`arrow-down ${
                                            clickedBooks.includes(book._id) ? "opened" : ""
                                        }`}
                                    />
                                </button>
                                <div
                                    className={`portfolio-book-content ${
                                        clickedBooks.includes(book._id) ? "clicked" : ""
                                    }`}
                                    onClick={handleOnClickBook}
                                    data-id={book._id}
                                >
                                    <div>
                    <span className="portfolio-book-content-title">
                      <span className="bolder">{bookProperties.title[0].value}</span>{" "}
                        {bookProperties.type && `(${bookProperties.type[0].value})`}
                    </span>
                                        <br/>
                                        <span className="portfolio-book-content-subtitle">
                      {bookProperties.author[0].value}
                    </span>
                                    </div>
                                    <CustomWidthTooltip title={<div className={"tooltip"}
                                                                    dangerouslySetInnerHTML={{__html: bookProperties.text[0].value}}/>}>
                                        <p className="portfolio-book-content-text"
                                           dangerouslySetInnerHTML={{__html: bookProperties.text[0].value}}/>
                                    </CustomWidthTooltip>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <Presentation/>
        </>
    );
};

export default Portfolio;
