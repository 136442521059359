const aggregateProperties = (properties: Array<any>) => {
  let propertiesAgg: any = {};
  properties.forEach((property: any) => {
    if (!property.listOfValues || property.listOfValues.length == 0) {
      let settingsAgg: any = {};
      property.settings.forEach((settings: any) => {
        settingsAgg[settings.name] = settings.value;
      });
      propertiesAgg[property.name] = propertiesAgg[property.name] || [];
      let propertyAgg: any = {};
      propertyAgg.settings = settingsAgg;
      propertyAgg.value = property.value;
      propertiesAgg[property.name].push(propertyAgg);
    } else {
      property.listOfValues.forEach((value: any) => {
        let settingsAgg: any = {};
        value.settings.forEach((settings: any) => {
          settingsAgg[settings.name] = settings.value;
        });
        propertiesAgg[value.name] = propertiesAgg[value.name] || [];
        let propertyAgg: any = {};
        propertyAgg.settings = settingsAgg;
        propertyAgg.value = value.value;
        propertiesAgg[property.name].push(propertyAgg);
      });
    }
  });
  return propertiesAgg;
};

export default aggregateProperties;
