import React from "react";
import "./Blog.css";

const TextComponent = ({ properties }: any) => {
  return (
    <div className="article-content-offset">
      <h3>{properties.title[0].value}</h3>
      <div>
        <p
          className="regular"
          style={{ marginBottom: 45 }}
          dangerouslySetInnerHTML={{ __html: properties.text[0].value }}
        />
      </div>
    </div>
  );
};
export default TextComponent;
