import React from "react";
import { ReactComponent as ArrowCarousel } from "../../assets/img/svg/arrow-left-carousel.svg";
import "./Blog.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Carousel } from "react-responsive-carousel";

const TextAndImagesComponent = ({ properties }: any) => {
  const handleCarouselChange = (event: any) => {};

  const carrousel = () => {
    return (
      <Carousel showThumbs={false} showStatus={false}>
        {properties.images.map((img: any) => {
          return (
            <img
              className={"carousel"}
              src={img.value}
              alt={img.settings.alt}
            />
          );
        })}
      </Carousel>
    );
  };

  return (
    <div>
      <div className="article-content-offset">
        <h3>{properties.title[0].value}</h3>
        <p
          className="regular"
          dangerouslySetInnerHTML={{ __html: properties.text[0].value }}
        />
      </div>

      <div className="article-content-media-container">
        <div className="carousel-container">
          <div className="carousel">
            {properties.images.length > 1 && carrousel()}
            {properties.images.length == 1 && (
              <img
                className="carousel"
                src={properties.images[0].value}
                alt={properties.images[0].settings.alt}
              />
            )}
          </div>
        </div>
        <div className="right-content">
          <div className="border-top" />
          <div
            className="caption-regular"
            dangerouslySetInnerHTML={{
              __html: properties.description[0].value,
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default TextAndImagesComponent;
