import React from "react";
import "./Disclaimer.css";
import { FormattedMessage } from "react-intl";

const Disclaimer = () => {
  return (
    <>
      <div className="legal-notices-container">
        <div>
          <h1 className="legal-notices-title">
            <FormattedMessage id={"lang.legal_notices.title"} />
          </h1>
          <p>
            <FormattedMessage id={"lang.legal_notices.text"} />
          </p>
          <p>
            <FormattedMessage id={"lang.legal_notices.text2"} />
          </p>
          <p>
            <FormattedMessage id={"lang.legal_notices.text3"} />
          </p>
          <p>
            <FormattedMessage id={"lang.legal_notices.text4"} />
          </p>
          <p>
            <FormattedMessage id={"lang.legal_notices.text5"} />
          </p>
          <p>
            <FormattedMessage id={"lang.legal_notices.text6"} />
          </p>
          <p>
            <FormattedMessage id={"lang.legal_notices.text7"} />
          </p>
          <p>
            <FormattedMessage id={"lang.legal_notices.text8"} />
          </p>
          <p>
            <FormattedMessage id={"lang.legal_notices.text9"} />
          </p>
          <p>
            <FormattedMessage id={"lang.legal_notices.text10"} />
          </p>
        </div>
        <div>
          <h1 className="legal-notices-title">
            <FormattedMessage id={"lang.privacy_policy.title"} />
          </h1>

          <h2>
            <FormattedMessage id={"lang.privacy_policy.subtitle.title"} />
          </h2>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle.text"} />
          </p>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle.text2"} />
          </p>

          <h2>
            <FormattedMessage id={"lang.privacy_policy.subtitle2.title"} />
          </h2>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle2.text"} />
          </p>

          <h2>
            <FormattedMessage id={"lang.privacy_policy.subtitle3.title"} />
          </h2>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle3.text"} />
          </p>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle3.text2"} />
          </p>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle3.text3"} />
          </p>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle3.text4"} />
          </p>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle3.text5"} />
          </p>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle3.text6"} />
          </p>

          <h2>
            <FormattedMessage id={"lang.privacy_policy.subtitle4.title"} />
          </h2>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle4.text"} />
          </p>

          <h2>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.title"} />
          </h2>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text"} />
          </p>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text2"} />
          </p>
          <i>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.subtitle"} />
          </i>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text3"} />
          </p>
          <i>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.subtitle2"} />
          </i>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text4"} />
          </p>
          <i>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.subtitle3"} />
          </i>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text5"} />
          </p>
          <i>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.subtitle4"} />
          </i>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text6"} />
          </p>
          <i>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.subtitle5"} />
          </i>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text7"} />
          </p>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text8"} />
          </p>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text9"} />
          </p>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text10"} />
          </p>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text11"} />
          </p>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text12"} />
          </p>
          <i>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.subtitle6"} />
          </i>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text13"} />
          </p>
          <i>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.subtitle7"} />
          </i>
          <p>
            <FormattedMessage id={"lang.privacy_policy.subtitle5.text14"} />
          </p>
        </div>
        <div>
          <h1 className="legal-notices-title">
            <FormattedMessage id={"lang.terms.title"} />
          </h1>
          <p>
            <FormattedMessage id={"lang.terms.text"} />
          </p>

          <ol>
            <li>
              <h2>
                <FormattedMessage id={"lang.terms.subtitle.title"} />
              </h2>
              <p>
                <FormattedMessage id={"lang.terms.subtitle.text"} />
              </p>
            </li>

            <li>
              <h2>
                <FormattedMessage id={"lang.terms.subtitle2.title"} />
              </h2>
              <p>
                <FormattedMessage id={"lang.terms.subtitle2.text"} />
              </p>
            </li>

            <li>
              <h2>
                <FormattedMessage id={"lang.terms.subtitle3.title"} />
              </h2>
              <p>
                <FormattedMessage id={"lang.terms.subtitle3.text"} />
              </p>
            </li>

            <li>
              <h2>
                <FormattedMessage id={"lang.terms.subtitle4.title"} />
              </h2>
              <p>
                <FormattedMessage id={"lang.terms.subtitle4.text"} />
              </p>
            </li>

            <li>
              <h2>
                <FormattedMessage id={"lang.terms.subtitle5.title"} />
              </h2>
              <p>
                <FormattedMessage id={"lang.terms.subtitle5.text"} />
              </p>
              <p>
                <FormattedMessage id={"lang.terms.subtitle5.text2"} />
              </p>
            </li>

            <li>
              <h2>
                <FormattedMessage id={"lang.terms.subtitle6.title"} />
              </h2>
              <p>
                <FormattedMessage id={"lang.terms.subtitle6.text"} />
              </p>
            </li>

            <li>
              <h2>
                <FormattedMessage id={"lang.terms.subtitle7.title"} />
              </h2>
              <p>
                <FormattedMessage id={"lang.terms.subtitle7.text"} />
              </p>
            </li>

            <li>
              <h2>
                <FormattedMessage id={"lang.terms.subtitle8.title"} />
              </h2>
              <p>
                <FormattedMessage id={"lang.terms.subtitle8.text"} />
              </p>
            </li>

            <li>
              <h2>
                <FormattedMessage id={"lang.terms.subtitle9.title"} />
              </h2>
              <p>
                <FormattedMessage id={"lang.terms.subtitle9.text"} />
              </p>
              <p>
                <FormattedMessage id={"lang.terms.subtitle9.text2"} />
              </p>
            </li>

            <li>
              <h2>
                <FormattedMessage id={"lang.terms.subtitle10.title"} />
              </h2>
              <p>
                <FormattedMessage id={"lang.terms.subtitle10.text"} />
              </p>
            </li>

            <li>
              <h2>
                <FormattedMessage id={"lang.terms.subtitle11.title"} />
              </h2>
              <p>
                <FormattedMessage id={"lang.terms.subtitle11.text"} />
              </p>
            </li>

            <li>
              <h2>
                <FormattedMessage id={"lang.terms.subtitle12.title"} />
              </h2>
              <p>
                <FormattedMessage id={"lang.terms.subtitle12.text"} />
              </p>
            </li>

            <li>
              <h2>
                <FormattedMessage id={"lang.terms.subtitle13.title"} />
              </h2>
              <p>
                <FormattedMessage id={"lang.terms.subtitle13.text"} />
              </p>
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};

export default Disclaimer;
