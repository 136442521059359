import React, { useState } from "react";
import "./App.css";
import ScrollToTop from "./helpers/ScrollToTop";
import Navbar from "./components/Navbar/Navbar";
import Home from "./views/Home/Home";
import Footer from "./components/Footer/Footer";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Bio from "./views/Bio/Bio";
import Portfolio from "./views/Portfolio/Portfolio";
import Grow from "./views/Grow/Grow";
import Contact from "./views/Contact/Contact";
import Article from "./views/Article/Article";
import { IntlProvider } from "react-intl";
import frFRMessages from "locales/fr-FR.json";
import nlNLMessages from "locales/nl-NL.json";
import enUSMessages from "locales/en-US.json";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Modal from "react-modal";
import Disclaimer from "./views/Disclaimer/Disclaimer";
import Config from "./utils/Config";

const LANGUAGES: any = {
  fr: frFRMessages,
  nl: nlNLMessages,
  en: enUSMessages,
};

Modal.setAppElement("#root");

const App = () => {
  const [locale, setLocale] = useState<string>("fr");

  const changeLanguage = (locale: string) => {
    setLocale(locale);
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <IntlProvider
        locale={locale}
        defaultLocale={locale}
        messages={LANGUAGES[locale]}
      >
        <GoogleReCaptchaProvider
          reCaptchaKey="6LcP3OsiAAAAAHNOvMoDVtr1aPokPbQwajvU6lqP"
          language={locale ?? "fr"}
        >
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/bio" element={<Bio />} />
            <Route path="/article/:slug" element={<Article />} />
            <Route path="/portfolio" element={<Portfolio />} />
            {/*<Route path='/grow' element={<Grow />} />*/}
            <Route path="/contact" element={<Contact />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Footer />
        </GoogleReCaptchaProvider>
      </IntlProvider>
    </BrowserRouter>
  );
};

export default App;
