import React from "react";
import { ReactComponent as ArrowRight } from "../../assets/img/svg/arrow-right.svg";
import { ReactComponent as ExternalLink } from "../../assets/img/svg/external-link.svg";
import "./Blog.css";

const TextWithLinkComponent = ({ properties }: any) => {
  return (
    <div>
      <div className="article-content-offset">
        <h3>{properties.title[0].value}</h3>
        <div className="d-flex gap-30">
          <p
            className="regular"
            dangerouslySetInnerHTML={{ __html: properties.text[0].value }}
          />
          <div className="right-content">
            <div className="border-top" />
            <div className="caption-regular">
              {properties.annotation[0].value}
            </div>
          </div>
        </div>
        <div className="links-container">
          <button className="secondary">
            {properties.internalLink[0].value}{" "}
            <ArrowRight className="arrow-right" />
          </button>
          <button className="secondary">
            <ExternalLink className="external-link" />
            {properties.externalLink[0].value}{" "}
            <ArrowRight className="arrow-right" />
          </button>
        </div>
      </div>
    </div>
  );
};
export default TextWithLinkComponent;
