import React from "react";
import { ReactComponent as LeftQuote } from "../../assets/img/svg/left-quote.svg";
import { ReactComponent as RightQuote } from "../../assets/img/svg/right-quote.svg";
import QuoteComponent from "./QuoteComponent";
import TextAndImagesComponent from "./TextAndImagesComponent";
import TextComponent from "./TextComponent";
import TextWithLinkComponent from "./TextWithLinkComponent";
import TextAndVideoComponent from "./TextAndVideoComponent";

const BlogComponent = ({ properties, component }: any) => {
  let data: any = "";
  switch (component) {
    case "quoteComponent":
      data = <QuoteComponent properties={properties} />;
      break;
    case "textAndImagesComponent":
      data = <TextAndImagesComponent properties={properties} />;
      break;
    case "textAndVideoComponent":
      data = <TextAndVideoComponent properties={properties} />;
      break;
    case "textComponent":
      data = <TextComponent properties={properties} />;
      break;
    case "textWithLinkComponent":
      data = <TextWithLinkComponent properties={properties} />;
      break;
  }
  return data;
};
export default BlogComponent;
