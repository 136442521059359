import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { ReactComponent as SVGIconsCode } from "assets/img/svg/icons.code.svg";
import { ReactComponent as SVGIconsHeart } from "assets/img/svg/icons.heart.svg";

const Footer = () => {
  return (
    <footer>
      <div className="footer-top-container">
        <div className="footer-top-categories">
          <p className="bold">Catégories</p>
          <div className="caption-regular">Etymology</div>
          <div className="caption-regular">Brainstorming</div>
          <div className="caption-regular">English</div>
          <div className="caption-regular">Reading</div>
        </div>
        <div className="footer-top-navigation">
          <p className="bold">Navigation</p>
          <Link to={"/"}>
            <div className="caption-regular">Mes articles</div>
          </Link>
          <Link to={"/bio"}>
            <div className="caption-regular">Bio</div>
          </Link>
          <Link to={"/portfolio"}>
            <div className="caption-regular">Mes Traductions</div>
          </Link>
          {/*<Link to={"/grow"}>*/}
          {/*  <div className="caption-regular">Grow</div>*/}
          {/*</Link>*/}
          <Link to={"/contact"}>
            <div className="caption-regular">Contact</div>
          </Link>
        </div>
      </div>
      <div className="footer-bottom-container">
        <div className="caption-regular">© {new Date().getFullYear()}</div>
        <Link to="/disclaimer">
          <div className="caption-regular">Disclaimer</div>
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
