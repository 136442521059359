import React, { useRef } from "react";
import "./Blog.css";
import CardMedia from "@mui/material/CardMedia";

const TextAndVideoComponent = ({ properties }: any) => {
  return (
    <div>
      <div className="article-content-offset">
        <h3>{properties.title[0].value}</h3>
        <p
          className="regular"
          dangerouslySetInnerHTML={{ __html: properties.text[0].value }}
        ></p>
      </div>

      <div className="article-content-media-container">
        <div className="video-container">
          <CardMedia
            component="video"
            controls
            src={properties.video[0].value}
          />
        </div>
        <div className="right-content">
          <div className="border-top" />
          <div
            className="caption-regular"
            dangerouslySetInnerHTML={{
              __html: properties.videoDescription[0].value,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};
export default TextAndVideoComponent;
