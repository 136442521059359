import React from "react";
import { ReactComponent as LeftQuote } from "../../assets/img/svg/left-quote.svg";
import { ReactComponent as RightQuote } from "../../assets/img/svg/right-quote.svg";
import "./Blog.css";
const QuoteComponent = ({ properties }: any) => {
  return (
    <div className="quote-container">
      <LeftQuote className="left-quote" />
      <div className="quote">
        <h2 dangerouslySetInnerHTML={{ __html: properties.text[0].value }} />
        <p
          className="caption-regular"
          dangerouslySetInnerHTML={{ __html: properties.author[0].value }}
        ></p>
      </div>
      <RightQuote className="right-quote" />
    </div>
  );
};
export default QuoteComponent;
