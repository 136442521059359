const Config = {
  apis: {
    backend: process.env.REACT_APP_BACKEND_ENDPOINT as string,
    google_recaptcha_site_key: process.env
      .REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY as string,
    s3: process.env.REACT_APP_S3_URL as string
  },
};

export default Config;
