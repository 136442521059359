import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FormattedMessage, useIntl } from "react-intl";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import axios from "axios";
import "./ContactForm.css";
import { ReactComponent as ArrowRight } from "assets/img/svg/arrow-right.svg";
import { Link } from "react-router-dom";

import { endpoint } from "../../utils/API";

const ContactForm = ({ currentLocale, section }: any) => {
  const intl = useIntl();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  return !isSubmitted ? (
    <Formik
      initialValues={{
        name: "",
        email: "",
        subject: section ? section : "",
        text: "",
      }}
      validationSchema={Yup.object({
        name: Yup.string().required(
          intl.formatMessage({ id: "lang.stop_waiting.form.required" })
        ),
        email: Yup.string()
          .email(
            intl.formatMessage({ id: "lang.stop_waiting.form.invalid_email" })
          )
          .required(
            intl.formatMessage({ id: "lang.stop_waiting.form.required" })
          ),
        subject: Yup.string()
          .min(
            5,
            intl.formatMessage({ id: "lang.stop_waiting.form.minimum.5" })
          )
          .required(
            intl.formatMessage({ id: "lang.stop_waiting.form.required" })
          ),
        text: Yup.string()
          .min(
            10,
            intl.formatMessage({ id: "lang.stop_waiting.form.minimum.10" })
          )
          .required(
            intl.formatMessage({ id: "lang.stop_waiting.form.required" })
          ),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setIsSubmitting(true);

        executeRecaptcha?.("contact/post")
          .then((recaptchaToken) => {
            const data = { ...values, language: currentLocale, recaptchaToken };
            axios
              .post(`${endpoint}/contact`, data)
              .then(() => {
                setIsSubmitted(true);
                setSubmitting(false);
                setIsSubmitting(false);
                return;
              })
              .catch((err) => {
                console.log("error:", err);
                setIsSubmitting(false);
              });
          })
          .catch((err) => {
            setSubmitting(false);
            setIsSubmitting(false);
            console.log("error:", err);
          });
      }}
    >
      <div className="form-container">
        <Form>
          <FormattedMessage
            id={"lang.stop_waiting.form.field.name.placeholder"}
          >
            {(msg) => (
              <>
                <Field
                  name="name"
                  type="text"
                  required
                  render={({ field }: any) => (
                    <input
                      {...field}
                      placeholder={msg.toString()}
                      className={`${
                        field.value.length > 0 ? "input-filled" : ""
                      }`}
                    />
                  )}
                />
                <span className="error-text">
                  <ErrorMessage name="name" />
                </span>
              </>
            )}
          </FormattedMessage>

          <FormattedMessage
            id={"lang.stop_waiting.form.field.email.placeholder"}
          >
            {(msg) => (
              <>
                <Field
                  name="email"
                  type="text"
                  required
                  render={({ field }: any) => (
                    <input
                      {...field}
                      placeholder={msg.toString()}
                      className={`${
                        field.value.length > 0 ? "input-filled" : ""
                      }`}
                    />
                  )}
                />
                <span className="error-text">
                  <ErrorMessage name="email" />
                </span>
              </>
            )}
          </FormattedMessage>

          <FormattedMessage
            id={"lang.stop_waiting.form.field.subject.placeholder"}
          >
            {(msg) => (
              <>
                <Field
                  name="subject"
                  type="text"
                  required
                  render={({ field }: any) => (
                    <input
                      {...field}
                      placeholder={msg.toString()}
                      className={`${
                        field.value.length > 0 ? "input-filled" : ""
                      }`}
                      disabled={!!section}
                    />
                  )}
                />
                <span className="error-text">
                  <ErrorMessage name="subject" />
                </span>
              </>
            )}
          </FormattedMessage>

          <FormattedMessage
            id={"lang.stop_waiting.form.field.text.placeholder"}
          >
            {(msg) => (
              <>
                <Field
                  name="text"
                  type="textarea"
                  as="textarea"
                  placeholder={msg.toString()}
                  required
                  render={({ field }: any) => (
                    <textarea
                      {...field}
                      placeholder={msg.toString()}
                      className={`${
                        field.value.length > 0 ? "input-filled" : ""
                      }`}
                    />
                  )}
                />
                <span className="error-text">
                  <ErrorMessage name="text" />
                </span>
              </>
            )}
          </FormattedMessage>
          <div className="button-container">
            <button
              type="submit"
              className={`primary ${isSubmitting ? "button-disabled" : ""}`}
              disabled={isSubmitting}
            >
              <FormattedMessage
                id={
                  isSubmitting
                    ? "lang.stop_waiting.form.submitting"
                    : "lang.stop_waiting.form.submit"
                }
              />
              <ArrowRight className="arrow-right" />
            </button>
          </div>
        </Form>
      </div>
    </Formik>
  ) : (
    <div className="success-message">
      <h2>
        <FormattedMessage id={"lang.stop_waiting.form.success.title"} />
      </h2>
      <div>
        <p className="regular">
          <FormattedMessage id={"lang.stop_waiting.form.success.text"} />
          <br />
          <FormattedMessage id={"lang.stop_waiting.form.success.text2"} />
        </p>
      </div>
      <Link to={"/"}>
        <button className="primary-small">
          <FormattedMessage id={"lang.stop_waiting.form.success.cta"} />
        </button>
      </Link>
    </div>
  );
};

export default ContactForm;
